import { AfterContentInit, Component, EventEmitter, input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Color } from '@shared/utils/typescript';
import { VisibleHeightPipe } from '../pipes/visible-height.pipe';

@Component({
    standalone: true,
    selector: 'app-no-access',
    templateUrl: './no-access.component.html',
    styleUrl: './no-access.component.scss',
    imports: [IonicModule, VisibleHeightPipe],
})
export class NoAccessComponent implements AfterContentInit {
    @Output() openPaymentModal = new EventEmitter<void>();

    title = input.required<string>();
    subTitle = input.required<string>();
    buttonText = input.required<string>();
    buttonBackground = input<Color>(Color.Max);
    clientRects: DOMRect = {} as DOMRect;

    ngAfterContentInit() {
        //  This directive is used to manually set the height of an element based on window height
        //  and it's position in the DOM. Main usage at the moment is the no-access component, ensuring that
        //  the content inside is relatively-positioned centrally to avoid inline hard-coded padding/margin
        setTimeout(() => {
            //  Based on the way Ionic routing works by leaving behind the parent element's DOM in place,
            //  we need to ensure that we are always selecting the last item in the array as that will
            //  always be the current view
            const elems = document.getElementsByClassName('no-access');
            const elem = elems[elems.length - 1];

            this.clientRects = elem!.getBoundingClientRect();
        });
    }

    triggerPaymentModal(): void {
        this.openPaymentModal.emit();
    }
}
