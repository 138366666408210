import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'visibleHeight',
    standalone: true,
})
export class VisibleHeightPipe implements PipeTransform {
    transform(clientRects: DOMRect): number {
        return window.innerHeight - clientRects.y;
    }
}
