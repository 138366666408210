// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `workspace.json`.

import { mixpanelConfigProd } from '@shared/config/mixpanel-config';
import { Environment } from './environment.model';
import { firebaseConfigProd } from './firebase.config';
import { ProjectEnvironment } from '@frontend/configuration';

export const environment: Environment = {
    baseEnvironmentConfig: {
        production: true,
        environment: ProjectEnvironment.PRODUCTION,
        internalApiUrl: 'https://zigzag-internal-traffic-manager-prod.trafficmanager.net/',
        internalAuthSecret: 'ZAvw67ZWlx',
        applicationToInternalHashedApiKey:
            '$argon2id$v=19$m=4096,t=3,p=1$aWdmamRzaWZqZHNkZmRzZg$VYGU6nvTQptoy5ZCFSlKjDyd+exuy9SVrOQpglpp7uA',
    },
    firebaseConfig: firebaseConfigProd,
    streamChatApiKey: 'm4qu9fdm5ssy',
    streamChatAgentToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiUHVwcHlfQ29hY2gifQ.HWTa1Wdg-r-ylbVv5Bo7WJhL9cup9pbNDomzH4Ta6r4',
    mixpanelConfig: {
        platform: 'browser',
        projectToken: mixpanelConfigProd.projectToken,
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
